.flicking-arrow-prev,
.flicking-arrow-next {
  position: absolute;
  top: 50%;
  width: 64px;
  height: 64px;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 2;
}

.flicking-arrow-prev.is-circle,
.flicking-arrow-next.is-circle {
  background-color: #f2a65e;
  border-radius: 50%;
}

.flicking-arrow-disabled.is-circle {
  background-color: rgb(10 10 10 / 10%);
}

.flicking-arrow-prev.is-circle::before,
.flicking-arrow-prev.is-circle::after,
.flicking-arrow-next.is-circle::before,
.flicking-arrow-next.is-circle::after {
  background-color: white;
}

.flicking-arrow-prev {
  left: 10px;
}
.flicking-arrow-next {
  right: 10px;
}

.flicking-arrow-prev.is-outside {
  left: -74px;
}
.flicking-arrow-next.is-outside {
  right: -74px;
}

.flicking-arrow-prev::before,
.flicking-arrow-prev::after,
.flicking-arrow-next::before,
.flicking-arrow-next::after {
  content: "";
  width: 24px;
  height: 6px;
  position: absolute;
  background-color: #f2a65e;
}
.flicking-arrow-prev::before {
  top: 50%;
  left: 22px;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 0% 50%;
      -ms-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
}
.flicking-arrow-prev::after {
  top: calc(50% - 4px);
  left: 22px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 0% 50%;
      -ms-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
}
.flicking-arrow-next::before {
  top: 50%;
  right: 22px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 100% 50%;
      -ms-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
}
.flicking-arrow-next::after {
  top: calc(50% - 4px);
  right: 22px;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 100% 50%;
      -ms-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
}

.flicking-arrow-disabled,
.flicking-arrow-disabled {
  cursor: default;
}

.flicking-arrow-disabled::before,
.flicking-arrow-disabled::after,
.flicking-arrow-disabled::before,
.flicking-arrow-disabled::after {
  background-color: #e6e6e6;
}

.flicking-arrow-prev.is-thin::before,
.flicking-arrow-prev.is-thin::after,
.flicking-arrow-next.is-thin::before,
.flicking-arrow-next.is-thin::after{
  height: 3px;
}

.flicking-arrow-prev.is-thin::after,
.flicking-arrow-next.is-thin::after{
  top: calc(50% - 2px);
}
.flicking-pagination {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.flicking-pagination-bullets,
.flicking-pagination-scroll {
  font-size: 0;
}

.flicking-pagination-scroll {
  left: 50%;
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
  white-space: nowrap;
  overflow: hidden;
}

.flicking-pagination-scroll .flicking-pagination-slider {
  -webkit-transition: .2s transform;
  transition: .2s transform;
}
.flicking-pagination-scroll.flicking-pagination-uninitialized .flicking-pagination-slider,
.flicking-pagination-scroll.flicking-pagination-uninitialized .flicking-pagination-bullet {
  -webkit-transition: none;
  transition: none;
}

.flicking-pagination-bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: rgb(10 10 10 / 10%);
  cursor: pointer;
  font-size: 1rem;
}

.flicking-pagination-scroll .flicking-pagination-bullet {
  vertical-align: middle;
  position: relative;
  -webkit-transition: .2s transform;
  transition: .2s transform;
}

.flicking-pagination-bullet-active {
  background-color: #f2a65e;
}

.flicking-pagination-scroll .flicking-pagination-bullet {
  vertical-align: middle;
  position: relative;
  -webkit-transition: .2s transform,.2s left;
  transition: .2s transform,.2s left;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
}

.flicking-pagination-scroll .flicking-pagination-bullet-active {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.flicking-pagination-scroll .flicking-pagination-bullet-prev,
.flicking-pagination-scroll .flicking-pagination-bullet-next {
  -webkit-transform: scale(0.66);
      -ms-transform: scale(0.66);
          transform: scale(0.66);
}

.flicking-pagination-scroll .flicking-pagination-bullet-prev2,
.flicking-pagination-scroll .flicking-pagination-bullet-next2 {
  -webkit-transform: scale(0.33);
      -ms-transform: scale(0.33);
          transform: scale(0.33);
}
