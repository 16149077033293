/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Hero New';
  src: url('./assets/font/Hero\ New\ Regular.otf') format('truetype');

}

body {
  font-family: 'Hero New', sans-serif;
  font-weight: 100;
  font-size: 14px;
}

// toastr
@import 'ngx-toastr/toastr';


@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./components.scss";
@import './assets/scss/icon.scss';

// flicking carousel
@import '@egjs/flicking/dist/flicking.css';
@import "@egjs/flicking-plugins/dist/flicking-plugins.css";
@import "@egjs/flicking-plugins/dist/arrow.css";



body {
  // background-color: #f8f8f8;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


// for the inifinite Scroll 
::-webkit-scrollbar {
  background: transparent;
  width: 5px;
  height: 5px;
  overflow-x: hidden;
  display: block;
  // height: 36px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  // margin-bottom:100%;
}

::-webkit-scrollbar-thumb {
  background-color: #afafaf;
  border-right: none;
  border-left: none;
  border-radius: 50px;
}

//for ngx bullet color

// for toast messages
.ngx-toastr {
  animation: slide-in 0.1s ease;
  /* Apply the animation to ngx-toastr */
  background-color: #6016ef !important;
  border: 1px solid #6016ef !important;
  color: white !important;
  border-radius: 8px !important;
  margin-top: 90px !important;
  margin-right: 20px !important;
}

.toast-close-button {
  color: #6016ef !important;
  opacity: 1;
}

.toast-message {
  color: white !important;
  margin-top: 3px !important;
}

.toast-error {
  animation: slide-in 0.5s ease;
  background-color: #6016ef !important;
  border: 1px solid #6016ef !important;
  color: white !important;
  border-radius: 8px !important;
}

.flicking-arrow-prev{
  &::before, &::after{
    display: none;
  }
}

.flicking-arrow-next{
  &::before, &::after{
    display: none;
  }
}

.flicking-arrow-disabled{
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.ng-touched.ng-invalid{
  border-color: #c30010
}

.otp-input{
  @apply placeholder:text-theme-txt-lightGray/50;
  width: 45px !important;
  height: 45px !important;
  border-radius: 8px !important;
  &:focus{
    @apply outline-theme-primary-900
  }
}

.otp-input-container .n-o-c{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.otp-input.ng-valid.ng-dirty{
  @apply border-theme-border-bordergrey;
}