._icon{
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
}
._cart{
    mask-image: url("/assets/icons/add-to-cart.svg");
}

._backArrow{
    mask-image: url("../icons/backArrow.svg");
}
.carousel-prev{
    mask-image: url("../icons/carousel-prev.svg");
}
.carousel-next{
    mask-image: url("../icons/carousel-next.svg");
}
._incart{
    mask-image: url('../icons/inCartLogo.svg');
}
._deleteIcon{
    mask-image: url('../icons/delete.svg');
}
._emptyCart{
    mask-image: url('../icons/cartEmpty.svg');
}
._microphone{
    mask-image:url('/assets/icons/microphone.svg') ;
}
._uploadfile{
    mask-image: url('/assets/icons/uploadicon.svg');
}
._imgDelete{
    mask-image: url('/assets/icons/imgDelete.svg');
}
._contact{
    mask-image: url('/assets/icons/contact.svg');
}
._phone {
    mask-image: url('/assets/icons/phone.svg');
}

.arrow-left{
    mask-image: url('/assets/icons/left-arrow.svg');
}

.grid-view{
    mask-image: url('/assets/icons/grid-view.svg');
}

.insta-view{
    mask-image: url('/assets/icons/insta-view.svg');
}

.close-icon{
    mask-image: url('/assets/icons/close.svg');
}